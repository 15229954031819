import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'brain_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="brain_outline_24"><g fill="currentColor"><path d="M7.45 13.323a.9.9 0 00-.9 1.558 3.608 3.608 0 011.71 2.318.9.9 0 101.756-.398 5.408 5.408 0 00-2.565-3.478zm2.09-7.296a.9.9 0 00-1.26.18 1.596 1.596 0 01-1.282.643.9.9 0 100 1.8A3.396 3.396 0 009.72 7.287a.9.9 0 00-.18-1.26zm6.386.846a.9.9 0 00-1.782.254 4.404 4.404 0 003.1 3.59.9.9 0 10.513-1.725 2.604 2.604 0 01-1.83-2.12z" /><path clip-rule="evenodd" d="M2.1 14c0-.87.252-1.68.687-2.362a5.401 5.401 0 013.694-7.942A4.895 4.895 0 0112 1.834a4.895 4.895 0 015.519 1.862 5.401 5.401 0 013.694 7.942c.435.683.687 1.493.687 2.362a4.396 4.396 0 01-2.016 3.698 2.9 2.9 0 01-3.061 3.196A3.396 3.396 0 0112 21.75a3.396 3.396 0 01-4.823-.855 2.9 2.9 0 01-3.061-3.196A4.396 4.396 0 012.1 14zm5.703-9.03A3.099 3.099 0 0111.1 3.458v16.704a1.601 1.601 0 01-2.596-.594.9.9 0 00-1.14-.53 1.1 1.1 0 01-1.38-1.461.9.9 0 00-.484-1.176 2.601 2.601 0 112.114-4.751.9.9 0 00.772-1.626A4.386 4.386 0 006.5 9.6c-.87 0-1.68.252-2.363.688A3.6 3.6 0 017.116 5.42a.9.9 0 00.687-.45zM12.9 20.162c.287.272.674.438 1.1.438.418 0 .799-.16 1.084-.423A2.893 2.893 0 0114.1 18a.9.9 0 111.8 0c0 .6.48 1.087 1.076 1.1H17a1.1 1.1 0 001.016-1.523.9.9 0 01.484-1.176 2.601 2.601 0 001.458-3.253A5.378 5.378 0 0116.5 14.4a.9.9 0 010-1.8 3.6 3.6 0 00.385-7.18.9.9 0 01-.688-.45A3.099 3.099 0 0012.9 3.458z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24BrainOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24BrainOutline: FC<Icon24BrainOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24BrainOutline as any).mountIcon = mountIcon;

export default Icon24BrainOutline;
