import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'videocam_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="videocam_outline_20"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.96 13.705c-.035.385-.103.676-.233.93a2.5 2.5 0 01-1.092 1.092C12.1 16 11.4 16 10 16H5c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 01-1.093-1.092C1 14.1 1 13.4 1 12V8c0-1.4 0-2.1.272-2.635a2.5 2.5 0 011.093-1.093C2.9 4 3.6 4 5 4h5c1.4 0 2.1 0 2.635.272a2.5 2.5 0 011.092 1.093c.13.254.198.545.233.93l1.3-.742c1.666-.953 3.74.25 3.74 2.17v4.554c0 1.92-2.074 3.123-3.74 2.17l-1.3-.742zM5 5.5h5c.725 0 1.178.001 1.52.03.324.026.413.068.434.079a1 1 0 01.437.437c.01.02.053.11.08.434.028.342.029.795.029 1.52v4c0 .725-.001 1.178-.03 1.52-.026.324-.069.413-.079.434a1 1 0 01-.437.437c-.02.01-.11.053-.434.08-.342.028-.795.029-1.52.029H5c-.725 0-1.178-.001-1.52-.03-.324-.026-.413-.069-.434-.079a1 1 0 01-.437-.437c-.01-.02-.053-.11-.08-.434-.028-.342-.029-.795-.029-1.52V8c0-.725.001-1.178.03-1.52.026-.324.068-.413.079-.434a1 1 0 01.437-.437c.02-.01.11-.053.434-.08.342-.028.795-.029 1.52-.029zm9 6.5l2.004 1.145a1 1 0 001.496-.868V7.723a1 1 0 00-1.496-.868L14 8m0 0v4-4z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20VideocamOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20VideocamOutline: FC<Icon20VideocamOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20VideocamOutline as any).mountIcon = mountIcon;

export default Icon20VideocamOutline;
