import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'share_16';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" id="share_16"><path fill="currentColor" d="M7.958 1.499c.4-.007.747.171 1.022.343.278.174.61.426.998.72l4.704 3.57c.45.341.989.69 1.2 1.238a1.746 1.746 0 010 1.259c-.208.539-.734.884-1.176 1.219l-4.729 3.589c-.388.295-.72.546-.998.72-.275.172-.621.35-1.021.343a1.746 1.746 0 01-1.345-.669c-.247-.315-.313-.698-.342-1.021a14.888 14.888 0 01-.027-1.231l.002-.897c-.996.088-1.777.354-2.46.759-.863.51-1.616 1.268-2.456 2.286a.75.75 0 01-1.328-.477c0-2.036.453-3.966 1.57-5.497 1.03-1.41 2.573-2.414 4.674-2.874V4.42c-.002-.487-.003-.904.026-1.231.029-.323.095-.707.342-1.022a1.746 1.746 0 011.344-.67z" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16ShareProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Share: FC<Icon16ShareProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Share as any).mountIcon = mountIcon;

export default Icon16Share;
