import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'write_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="write_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.782 3.335a3 3 0 00-2.724 0c-.428.218-.8.591-1.23 1.023L4.677 17.508c-.354.355-.609.609-.818.897a4.5 4.5 0 00-.804 1.94C3 20.698 3 21.058 3 21.558V22.681c-.001.282-.002.628.098.937a2 2 0 001.284 1.284c.309.1.655.1.937.098H6.443c.5 0 .86 0 1.211-.055a4.5 4.5 0 001.941-.804c.288-.21.542-.464.896-.818l13.151-13.15c.432-.431.805-.803 1.023-1.231a3 3 0 000-2.724c-.218-.428-.591-.8-1.023-1.23l-.63-.63c-.43-.432-.802-.805-1.23-1.023zm-1.816 1.782a1 1 0 01.908 0c.106.054.237.169.819.75l.44.44c.582.582.696.713.75.819a1 1 0 010 .908c-.054.106-.168.237-.75.819l-.858.858-2.986-2.986.858-.858c.582-.581.713-.696.82-.75zM16.875 8.14L6.142 18.872c-.425.425-.56.564-.665.708a2.5 2.5 0 00-.446 1.079c-.028.176-.03.369-.03.97v.971c-.001.197 0 .305.004.383v.012h.012c.078.005.186.005.383.005h.97c.602 0 .795-.003.971-.03a2.5 2.5 0 001.079-.447c.144-.105.283-.24.708-.665l10.733-10.733-2.986-2.986z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28WriteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28WriteOutline: FC<Icon28WriteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28WriteOutline as any).mountIcon = mountIcon;

export default Icon28WriteOutline;
