import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'list_like_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="list_like_outline_28"><path d="M21.46 11.998c2.5 0 4.54 1.99 4.54 4.459 0 2.322-.913 3.5-4.426 6.19l-1.464 1.111c-.899.682-2.123.72-3.058.114l-.162-.114-1.51-1.147-.57-.442C11.804 19.808 11 18.636 11 16.457c0-2.47 2.04-4.459 4.54-4.459.929 0 1.784.292 2.55.849l.207.158.202.173.217-.183c.667-.534 1.406-.862 2.204-.963l.268-.025zm.063 1.998l-.224.007-.186.02a2.283 2.283 0 00-.937.375l-.173.127-1.19 1a.5.5 0 01-.646-.002l-1.126-.961c-.477-.385-.964-.565-1.502-.565-1.409 0-2.539 1.108-2.539 2.46 0 1.407.447 2.11 3.072 4.162l.518.4 1.509 1.147a.663.663 0 00.71.059l.092-.06 1.692-1.286C23.524 18.62 24 17.907 24 16.457c0-1.298-1.042-2.37-2.317-2.455zM8 17.996a1 1 0 110 2H4a1 1 0 110-2zm0-5.998a1 1 0 110 2H4a1 1 0 110-2zM22 6a1 1 0 110 2H4a1 1 0 110-2z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ListLikeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ListLikeOutline: FC<Icon28ListLikeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ListLikeOutline as any).mountIcon = mountIcon;

export default Icon28ListLikeOutline;
